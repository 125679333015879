import Table from "@tiptap/extension-table";

export default Table.extend({

	addAttributes() {
		return {
			...this.parent?.(),
      borderless: {
        default: 0,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: element => element.getAttribute('data-borderless'),
        // … and customize the HTML rendering.
        renderHTML: attributes => {
          return {
            'data-borderless': attributes.borderless,
            'class': attributes.borderless == 1 ? 'borderless' : ''
          }
        },
      },
		};
	},

  addCommands() {
    return {
			...this.parent?.(),
      setBorderless: () => ({ chain }) => {
        return chain()
          .updateAttributes(this.name, { borderless: 1 })
          .run()
      },
      unsetBorderless: () => ({ chain }) => {
        return chain()
          .updateAttributes(this.name, { borderless: 0 })
          .run()
      },
    }
  },
});
