import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Questionnaire from '@/components/editor/plugins/questionnaire/Questionnaire';

export default Node.create({
    name: 'questionnaire',
    group: 'block',

    addAttributes() {
        return {
            title: {
                default: "",
            },
            isMarked: {
                default: false,
            },
            uniqueAnswers: {
                default: true,
            },
            questions: {
                default: [],
                parseHTML: element => {
                    return JSON.parse(element.getAttribute('questions'));
                },
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'questionnaire',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['questionnaire', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(Questionnaire);
    },
})