<template>
  <div class="composer-wrapper d-flex justify-content-center py-3 flex-grow-1" :class="{ disabled: lock }">
    <div class="page p-3" :class="templateStyle">
      <editor-content v-if="header" :editor="header" class="mb-4" />
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
import { EditorContent } from "@tiptap/vue-2";
import { mapState } from "vuex";

export default {
  components: {
    EditorContent,
    // BubbleMenu,
  },
  props: {
    editor: {
      required: true,
    },
    header: {
      required: false,
    },
    footer: {
      required: false,
    },
    template: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("editor", ["lock"]),
    templateStyle() {
      return {
        "auth-certificate": this.template === "auth-certificate",
        "collectif-auth-certificate":
          this.template === "collectif-auth-certificate",
      };
    },
  },
  methods: {
    addImage() {
      const url = window.prompt(
        "Image URL",
        // Using picsum.photo/id/... and NOT
        // picsum.photos/w/h or the image will
        // change when we change the size and
        // tiptap redraws
        `https://picsum.photos/id/${
          Math.floor(Math.random() * 200) + 1
        }/200/300`
      );
      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run();
      }
    },
  },
  mounted() {
    // setTimeout(() => console.log(_.cloneDeep(this.editor.getJSON())), 1000);
  },
};
</script>

<style lang="scss" scoped>
.composer-wrapper {
  background-color: #f8f8f9;
  height: 0;
  overflow-y: scroll;
}

.page {
  background-color: $body-bg;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  width: 732px;
  min-height: 1035px;
  height: fit-content;
}

.controls {
  padding: 10px;
  border-bottom: 3px solid #eee;
  display: flex;
  width: 100%;
  align-items: center;
  button {
    background: none;
    font-weight: bold;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    margin-right: 4px;
    align-items: center;
    color: #0d0d0d;
    padding: 7px;
    border: 1px solid #ccc;
    &.is-active {
      background: #ddd;
    }
  }
}

.ProseMirror {
  &:focus,
  &:active {
    outline: none;
  }
}

img.custom-image {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &.ProseMirror-selectednode {
    outline: 3px solid #68cef8;
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.8;
  
  * {
    pointer-events: none;
  }
}
</style>