import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import DynamicField from '@/components/editor/plugins/dynamic-field/DynamicField';

export default Node.create({
    name: 'dynamicField',
    group: 'inline',
    inline: true,
    draggable: true,

    addAttributes() {
        return {
            type: {
                default: "",
            },
            label: {
                default: "",
            },
            format: {
                default: "",
            },
            formula: {
                default: "",
            },
            formulatype: {
                default: "number",
            },
            formuladate: {
                default: "",
            },
            daystoadd: {
                default: 0,
            },
            content: {
                default: "",
            },
            autodate: {
                default: false,
            },
            required: {
                default: true,
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'dynamic-field',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['dynamic-field', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(DynamicField)
    },
})