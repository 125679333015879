<template>
  <node-view-wrapper class="header" :class="{ separator: isEditor }">
    <span
      v-if="isEditor"
      class="label"
      contenteditable="false"
    >
      En-tête
    </span>
    <node-view-content
      as="div"
      class="header-content" 
    />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },

  props: nodeViewProps,

  computed: {
    isEditor() {
      return this.$route.name === "editor";
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #fff;
  position: relative;
  margin: -3rem 0 2.5rem -3rem !important;
  width: calc(100% + 6rem);

  &.separator {
    border-bottom: 3px dashed #ccc;
  }
}

.header .label {
  margin-left: 1rem;
  background-color: #ccc;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  bottom: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.header-content {
  margin: 1rem 1rem 2.5rem;
//   padding: 0.5rem;
//   border: 2px dashed #0d0d0d20;
//   border-radius: 0.5rem;
//   display: block;
}
</style>