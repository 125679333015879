<template>
  <node-view-wrapper
    class="dynamic-field"
    as="span"
    draggable="true"
    data-drag-handle
  >
    <span
      v-if="isNotEditable"
      class="field-wrapper"
      :style="{ borderColor: getBorderColor }"
      :class="{ fill: !isEditor }"
      contenteditable="false"
    >
      <span v-if="['fixed', 'formula'].includes(node.attrs.type) && !isEditor">
        {{ getDynamicFieldContent }}
      </span>
      <span v-else>{{ node.attrs.label }}</span>
    </span>
    <template v-else>
      <span
        ref="editable"
        type="text"
        class="field-wrapper-editable d-inline"
        :class="{ 'is-required': node.attrs.required }"
        :style="{ borderColor: getBorderColor }"
        contenteditable="true"
        :placeholder="`${node.attrs.label}${node.attrs.required ? '*' : ''}`"
        @click="focusEditable"
        @focus="focusEditable"
        v-on="listeners"
      />
      <datetime
        v-if="node.attrs.format === 'date'"
        ref="datePicker"
        style="position: absolute; top: -999999px"
        @input="handleDateInput"
      />
    </template>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";
import dayjs from "dayjs";
Settings.defaultLocale = "fr";

export default {
  components: {
    NodeViewWrapper,
    Datetime,
  },

  props: nodeViewProps,

  computed: {
    getBorderColor() {
      if (this.node.attrs.type === "toFill") {
        return "#6D81E3";
      } else if (this.node.attrs.type === "fixed") {
        return "#F14A3F";
      } else {
        return "#10DA61";
      }
    },
    getDynamicFieldContent() {
      if (this.node.attrs.format === "date") {
        if (this.node.attrs.autodate) {
          return dayjs().format("DD/MM/YYYY");
        } else {
          return dayjs(this.node.attrs.content).format("DD/MM/YYYY");
        }
      }
      return this.node.attrs.content;
    },
    isEditor() {
      return this.$route.name === "editor";
    },
    isNotEditable() {
      return this.isEditor || this.node.attrs.type !== "toFill";
    },
    listeners() {
      // https://stackoverflow.com/questions/53899676/vue-2-contenteditable-with-v-model
      return { ...this.$listeners, input: this.onInput };
    },
  },

  watch: {
    /**
     * Update value of other occurrences of the same dynamic field
     */
    node: {
      handler: function (newObj, oldObj) {
        if (
          this.$refs.editable &&
          newObj.attrs.content === oldObj.attrs.content
        ) {
          this.$refs.editable.innerText = newObj.attrs.content;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$refs.editable) {
      this.$refs.editable.innerText = this.node.attrs.content;
    }
  },
  methods: {
    focusEditable() {
      if (this.$refs.datePicker) {
        this.$refs.datePicker.isOpen = true;
      }
    },
    handleDateInput(value) {
      if (!value) return;
      const dateStr = this.dateToStr(value);
      this.$refs.editable.innerText = dateStr;
      this.updateAttributes({ content: dateStr });
    },
    onInput(e) {
      // this.$emit('input', e.target.innerText);
      this.updateAttributes({ content: e.target.innerText });
      // this.node.attrs.content = e.target.innerText;
      if (this.isNotEditable) return;

      (function updateOtherOccurrencesRecursive(obj, field) {
        if (obj.hasOwnProperty("children")) {
          for (let i = 0; i < obj.children.length; i++) {
            updateOtherOccurrencesRecursive(obj.children[i], field);
            if (
              obj.children[i].spec &&
              obj.children[i].spec.node &&
              obj.children[i].spec.node.attrs &&
              obj.children[i].spec.node.attrs !== field &&
              obj.children[i].spec.node.attrs.type === field.type &&
              obj.children[i].spec.node.attrs.label === field.label
            ) {
              obj.children[i].spec.node.attrs.content = field.content;
            }
          }
        }
      })(this.editor.view.docView, this.node.attrs);
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-field {
  white-space: nowrap;
  margin: 0 2px;
  cursor: grab;
}
.field-wrapper {
  white-space: nowrap;
  color: $black-400;
  padding: 0px 2px;
  font-weight: $font-weight-bolder;
  border-radius: $border-radius;
  border: 1.5px dashed;
}
.field-wrapper-editable {
  cursor: text;
  color: $black-400;
  padding: 0px 4px;
  font-weight: $font-weight-bolder;
  border-radius: $border-radius;
  display: inline-block !important;
  border: 1px dashed;
}

.fill {
  border: none;
  font-weight: $font-weight-bold;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: $vitalsign-blue;
}
</style>