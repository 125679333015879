<template>
  <node-view-wrapper class="checkboxes">
    <div
      v-for="(checkbox, idx) in node.attrs.checkboxes"
      :key="idx"
      class="checkbox"
    >
      <div v-if="isEditor" class="d-flex flex-row align-items-center">
        <b-form-checkbox
          v-if="node.attrs.uniqueAnswers == 'false'"
          v-model="checkbox.selected"
          :name="`checkbox-${uniqueId}-${idx}`"
          disabled
        >
        </b-form-checkbox>
        <b-form-radio
          v-else
          v-model="checkbox.selected"
          :name="`checkbox-${uniqueId}-${idx}`"
          disabled
        />
        <span
          contenteditable="true"
          v-once
          @input="updateCheckboxLabel($event, idx)"
          >{{ checkbox.label }}</span
        >
      </div>
      <div v-else>
        <b-form-checkbox
          v-if="node.attrs.uniqueAnswers == 'false'"
          v-model="checkbox.selected"
          :class="getInputClass"
          :name="`checkbox-${uniqueId}-${idx}`"
          :id="`checkbox-${uniqueId}-${idx}`"
          @change="updateCheckbox($event, idx)"
        >
          {{ checkbox.label }}
        </b-form-checkbox>
        <b-form-radio
          v-else
          v-model="checkbox.selected"
          :class="getInputClass"
          :name="`checkbox-${uniqueId}-${idx}`"
          :id="`checkbox-${uniqueId}-${idx}`"
          :value="checkbox.label"
          @change="updateRadio($event, idx)"
        >
          {{ checkbox.label }}
        </b-form-radio>
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";
import uniqid from "uniqid";

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  data() {
    return {
      uniqueId: uniqid(),
    };
  },
  computed: {
    isEditor() {
      return this.$route.name === "editor";
    },
    getInputClass() {
      return this.node.attrs.color === "default"
        ? ""
        : `${this.node.attrs.color}-input`;
    },
  },
  methods: {
    updateCheckbox(newValue, idx) {
      const currentCheckboxesArray = _.cloneDeep(this.node.attrs.checkboxes);
      currentCheckboxesArray[idx].selected = newValue;
      currentCheckboxesArray.selected = currentCheckboxesArray.filter(e => e.selected).map(e => e.label).join(', ');

      this.updateAttributes({
        checkboxes: _.cloneDeep(currentCheckboxesArray),
      });

      if (newValue) {
        // adds checked attr to input tag for pdf rendering
        document
          .getElementById(`checkbox-${this.uniqueId}-${idx}`)
          .setAttribute("checked", true);
      } else {
        // removes checked attr to input tag for pdf rendering
        document.getElementById(`checkbox-${this.uniqueId}-${idx}`).removeAttribute("checked");
      }
    },
    updateRadio(newValue, idx) {
      const currentCheckboxesArray = _.cloneDeep(this.node.attrs.checkboxes);
      // Remove previous selection
      for (let i = 0; i < currentCheckboxesArray.length; i++) {
        currentCheckboxesArray[i].selected = false;
        document.getElementById(`checkbox-${this.uniqueId}-${i}`).removeAttribute("checked");
      }

      currentCheckboxesArray[idx].selected = newValue;
      currentCheckboxesArray.selected = currentCheckboxesArray[idx].label;

      // Set selection
      this.updateAttributes({
        checkboxes: _.cloneDeep(currentCheckboxesArray),
      });

      console.log(
        document.getElementById(`checkbox-${this.uniqueId}-${idx}`)
      );

      // removes checked attr to input tag for pdf rendering
      this.node.attrs.checkboxes.forEach((checkbox, index) => {
        document
          .getElementById(`checkbox-${this.uniqueId}-${index}`)
          .removeAttribute("checked");
      });

      // adds checked attr to input tag for pdf rendering
      document
        .getElementById(`checkbox-${this.uniqueId}-${idx}`)
        .setAttribute("checked", true);
    },
    updateCheckboxLabel(e, idx) {
      const currentCheckboxesArray = _.cloneDeep(this.node.attrs.checkboxes);
      currentCheckboxesArray[idx].label = e.target.innerText;
      this.updateAttributes({
        checkboxes: _.cloneDeep(currentCheckboxesArray),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxes {
  user-select: none;
}
.checkbox {
  margin: 8px 0;
}
</style>