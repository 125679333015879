import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Header from './Header.vue'

export default Node.create({
  name: 'Header',

  group: 'block',

  // content: 'inline*',
  content: 'block*',

  parseHTML() {
    return [
      {
        tag: 'header',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['header', mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return VueNodeViewRenderer(Header)
  },
})