<template>
  <node-view-wrapper class="questionnaire">
    <div
      v-for="(question, idx) in node.attrs.questions"
      :key="idx"
      class="questionnaire-wrapper"
    >
      <p class="title">
        {{ idx + 1 }}.
        <span
          v-once
          contenteditable="true"
          @input="updateQuestionLabel($event, idx)"
        >
          {{ question.label }}
        </span>
      </p>
      <div
        v-for="(answer, answerIdx) in question.answers"
        :key="answerIdx"
      >
        <div
          v-if="isEditor"
          class="answer d-flex flex-row align-items-center"
        >
          <b-form-checkbox
            v-if="!node.attrs.uniqueAnswers"
            v-model="answer.selected"
            :name="`question-${idx}-checkbox-${answerIdx}`"
            disabled
          />
          <b-form-radio
            v-if="node.attrs.uniqueAnswers"
            v-model="question.selected"
            :name="`question-${idx}-select-${answerIdx}`"
            disabled
          />
          <span
            v-once
            contenteditable="true"
            @input="updateAnswerLabel($event, idx, answerIdx)"
          >
            {{ answer.label }}
          </span>
          <p
            v-if="node.attrs.isMarked"
            class="ml-1 mb-0"
          >
            ({{ answer.marks }} pts)
          </p>
        </div>
        <div
          v-else
          class="answer d-flex flex-row align-items-center"
        >
          <b-form-checkbox
            v-if="!node.attrs.uniqueAnswers"
            v-model="answer.selected"
            :name="`question-${idx}-checkbox-${answerIdx}`"
            @change.stop="updateCheckbox($event, idx, answerIdx)"
          >
            {{ answer.label }}
          </b-form-checkbox>
          <b-form-radio
            v-else
            v-model="question.selected"
            :name="`question-${idx}-select-${answerIdx}`"
            :value="answer.label"
            @change="updateRadio(idx, answerIdx)"
          >
            {{ answer.label }}
          </b-form-radio>
          <p
            v-if="node.attrs.isMarked"
            class="ml-1 mb-0"
          >
            ({{ answer.marks }} pts)
          </p>
        </div>
      </div>
    </div>
    <p v-if="node.attrs.isMarked">
      Total: {{ getTotalPoints }} pts
    </p>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  data() {
    return {};
  },
  computed: {
    getTotalPoints() {
      let total = 0;
      // si checkboxes
      if (!this.node.attrs.uniqueAnswers) {
        this.node.attrs.questions.forEach((question) => {
          question.answers.forEach((answer) => {
            if (answer.selected && this.node.attrs.isMarked) {
              total += parseInt(answer.marks);
            }
          });
        });
        // si radio
      } else {
        this.node.attrs.questions.forEach((question) => {
          let answer = question.answers.find(
            (answer) => answer.label === question.selected
          );
          console.log(answer);
          if (answer) {
            total += parseInt(answer.marks);
          }
        });
      }
      return total;
    },
    isEditor() {
      return this.$route.name === "editor";
    },
    listeners() {
      // https://stackoverflow.com/questions/53899676/vue-2-contenteditable-with-v-model
      return { ...this.$listeners, input: this.onInput };
    },
  },
  methods: {
    updateCheckbox(newValue, questionIdx, answerIdx) {
      let currentQuestionsArray = _.cloneDeep(this.node.attrs.questions);
      currentQuestionsArray[questionIdx].answers[answerIdx].selected = newValue;

      this.updateAttributes({
        questions: _.cloneDeep(currentQuestionsArray),
      });
    },
    updateRadio(questionIdx, answerIdx) {
      let currentQuestionsArray = _.cloneDeep(this.node.attrs.questions);
      currentQuestionsArray[questionIdx].selected =
        currentQuestionsArray[questionIdx].answers[answerIdx].label;

      this.updateAttributes({
        questions: _.cloneDeep(currentQuestionsArray),
      });
    },
    updateQuestionLabel(e, questionIdx) {
      let currentQuestionsArray = _.cloneDeep(this.node.attrs.questions);
      currentQuestionsArray[questionIdx].label = e.target.innerText;
      this.updateAttributes({
        questions: _.cloneDeep(currentQuestionsArray),
      });
    },
    updateAnswerLabel(e, questionIdx, answerIdx) {
      let currentQuestionsArray = _.cloneDeep(this.node.attrs.questions);
      currentQuestionsArray[questionIdx].answers[answerIdx].label = e.target.innerText;
      this.updateAttributes({
        questions: _.cloneDeep(currentQuestionsArray),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire {
  user-select: none;
}

.questionnaire-wrapper {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 4px;
}

.answer {
  margin-bottom: 4px;
}
</style>