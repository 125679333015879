import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Checkboxes from '@/components/editor/plugins/checkboxes/Checkboxes';

export default Node.create({
    name: 'checkboxes',
    group: 'block',

    addAttributes() {
        return {
            label: {
                default: "",
                default: true,
                parseHTML: element => {
                    return decodeURI(element.getAttribute('label'));
                },
            },
            uniqueAnswers: {
                default: true,
                parseHTML: element => {
                    return decodeURI(element.getAttribute('uniqueAnswers'));
                },
            },
            color: {
                default: "default",
                parseHTML: element => {
                    return decodeURI(element.getAttribute('color'));
                },
            },
            checkboxes: {
                default: [],
                parseHTML: element => {
                    return JSON.parse(decodeURI(element.getAttribute('checkboxes')));
                },
            },
            required: {
                default: false,
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'checkboxes',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['checkboxes', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(Checkboxes)
    },
})