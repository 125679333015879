import Image from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/core';

export default Image.extend({
    name: 'custom-image',

    addAttributes() {
        return {
            ...Image.config.addAttributes(),
            float: {
                default: 'none',
                rendered: false,
            },
            alignment: {
                default: 'left',
                rendered: false,
            },
            width: {
              default: 20,
              parseHTML: element => element.style.width.replace(/['"]+/g, ''),
              renderHTML: attributes => {
                return {
                  style: `width: ${attributes.width}%;`,
                }
              },
            },
        };
    },

    addCommands() {
        return {
            setImage: (options) => ({ tr, commands }) => {
                if(tr.selection?.node?.type?.name == 'custom-image') {
                    return commands.updateAttributes('custom-image', options);
                } else {
                    return commands.insertContent({
                        type: this.name,
                        attrs: options,
                    });
                }
            },
            setAlignment: alignment => ({ commands }) => {
                return commands.updateAttributes('custom-image', { float: 'none', alignment })
            },
            setFloat: float => ({ commands }) => {
                return commands.updateAttributes('custom-image', { float, alignment: null })
            },
            setWidth: width => ({ commands }) => {       
                return commands.updateAttributes('custom-image', { width: width });
            },
        }
    },

    renderHTML({ node, HTMLAttributes }) {

        HTMLAttributes.class = ' custom-image-float-' + node.attrs.float;
        if (node.attrs.alignment) {
            HTMLAttributes.class += ' custom-image-align-' + node.attrs.alignment;
        }

        return [
            'img',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
        ];
    }
});