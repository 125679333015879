<template>
  <node-view-wrapper class="footer" :class="{ separator: isEditor }">
    <node-view-content
      as="div"
      class="footer-content" 
    />
    <span
      v-if="isEditor"
      class="label"
      contenteditable="false"
    >
      Pied de page
    </span>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },

  props: nodeViewProps,

  computed: {
    isEditor() {
      return this.$route.name === "editor";
    },
  },
};
</script>

<style lang="scss">
.footer {
  background: #fff;
  position: relative;
  margin: 0 -3rem -2.5rem -3rem !important;
  width: calc(100% + 6rem);

  &.separator {
    border-top: 3px dashed #ccc;
    border-bottom: 3px dashed #ccc;
  }
}

.footer .label {
  margin-left: 1rem;
  background-color: #ccc;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.footer-content {
  margin: 2.5rem 1rem 1rem;
//   padding: 0.5rem;
//   border: 2px dashed #0d0d0d20;
//   border-radius: 0.5rem;
//   display: block;
}
</style>